import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
  {/* ===========================
    =====>> Header Top <<===== */}
  <header id="header-top" className="header-one">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 header-one-col">
          <div className="header-item mobile-none">
            <a href="index.html" className="header-logo">
              <img src="assets/img/logo.png" alt="" />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 header-one-col ">
          {/* <div class="header-item">
                  <div class="header-logo-center media">
                      <img src="assets/img/logo/h1.png" class="work-mean" alt="Work Mean">
                      <div class="header-logo-center-text">
                          <span>20%</span> off for installation
                          <br> CCTV Camera
                      </div>
                  </div>
              </div> */}
        </div>
        <div className="col-lg-6 col-sm-6 header-one-col ">
          <div className="header-item">
            <div className="header-logo-right media">
              <img src="assets/img/svg-icon/call.png" alt="" />
              <div className="header-logo-center-text">
                Call us
                <strong>9504950450</strong>
              </div>
              <img src="assets/img/svg-icon/email.png" alt="" />
              <div className="header-logo-center-text">
                Mail
                <strong>admin@technofixes.com</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  {/* =====>> End Header Top <<===== 
    =========================== */}
  {/* ===========================
    =====>> Top Menu <<===== */}
  <div id="menu-1" className="shrt-menu menu-1">
    <nav className="navbar navbar-expand-lg top-nav" id="cssmenu">
      <div className="container navbar-collapse">
        <div className="logo desktop-none">
          <a href="index.html" className="header-logo">
            <img src="assets/img/logo.png" alt="" />
          </a>
        </div>
        <div className="button" />
        <ul className="navbar-nav mr-auto" id="nav">
          <li className="active">
            <a href="index.html">Home</a>
          </li>
          <li>
            <a href="#">About Us</a>
          </li>
          <li>
            <a href="about.html"> Franchise</a>
          </li>
          <li>
            <a href="services.html">Services</a>
          </li>
          <li>
            <a href="shop.html">Blog</a>
          </li>
          <li>
            <a href="news.html">Contact Us</a>
          </li>
        </ul>
        {/*  <div class="form-inline schedule-right">
              <div class="schedule bg-com mobile-none">
                  <img src="assets/img/svg-icon/calander.svg" alt="calander">
                  <span>Schedule An</span>
                  <strong>Appointment</strong>
              </div>
          </div> */}
      </div>
    </nav>
  </div>
  {/* =====>> End Top Menu <<===== 
    =========================== */}
  {/* ===========================
    =====>> Secrius Hero <<===== */}
  {/* =====>> End Secrius Hero <<===== 
    =========================== */}
  <section className="home-three-hero">
    <div className="hero-three-slider owl-carousel owl-theme">
      <div className="hero-three-item hero-bg-three-one bg-com">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="hero-three-text text-center">
                <h6>More then services</h6>
                <h1 className="hero-three-title">
                  We take care of your home &amp; other things
                </h1>
                <a href="about.html" className="btn-1 hover-effect">
                  lEARN mORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-three-item hero-bg-three-two bg-com">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="hero-three-text text-center">
                <h6>More then services</h6>
                <h1 className="hero-three-title">
                  We take care of your home &amp; other things
                </h1>
                <a href="about.html" className="btn-1 hover-effect">
                  lEARN mORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-three-item hero-bg-three-three bg-com">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="hero-three-text text-center">
                <h6>More then services</h6>
                <h1 className="hero-three-title">
                  We take care of your home &amp; other things
                </h1>
                <a href="about.html" className="btn-1 hover-effect">
                  lEARN mORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ===========================
    =====>> Services <<===== */}
  <section id="services-area" className="pt-110 ">
    <div className="services-bg bg-com " />
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="services-title">
            We are specialized architecht for entertainment spaces
          </h3>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="services-item wow fadeInUp" data-wow-delay=".2s">
            <img src="assets/img/s1.jpg" alt="services" />
            <h5 className="services-title-title">MEDIA ROOMS</h5>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 ">
          <div className="services-item wow fadeInUp" data-wow-delay=".4s">
            <img src="assets/img/s2.jpg" alt="services" />
            <h5 className="services-title-title">DEDICATED DENS</h5>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="services-item wow fadeInUp" data-wow-delay=".6s">
            <img src="assets/img/s3.jpg" alt="services" />
            <h5 className="services-title-title">MUSIC SYSTEMS </h5>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="services-item wow fadeInUp" data-wow-delay=".8s">
            <img src="assets/img/s4.jpg" alt="services" />
            <h5 className="services-title-title">CINEMA SPACES</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Services <<===== 
    =========================== */}
  {/* ===========================
    =====>> Installation <<===== */}
  <section id="installation-area" className="pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-7 installation-col">
          <div className="installation-img">
            <img src="assets/img/install.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-5 installation-col">
          <div className="installation-content">
            <h2 className="hading-title">
              Home
              <span>Cinema</span>{" "}
            </h2>
            <p className="blue">
              Our Home Cinema systems meet defined industry standards in a
              dedicated space offering a projected image, fully immersive
              surround sound, acoustic design and access to a galaxy of content.
            </p>
            <a href="#" className="btn-1 hover-effect">
              Call 9940045255
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Installation <<===== 
    =========================== */}
  {/* ===========================
    =====>> Feature <<===== */}
  <section
    id="feature-area"
    className="feature-bg bg-com jarallax pt-120 pb-120"
    data-speed="-0.2"
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="feature-left">
            <div
              className="feature-left-item hover-effect wow fadeInDown"
              data-wow-delay=".2s"
            >
              <span>
                {" "}
                <img src="assets/img/part-1.png" alt="" />
              </span>
            </div>
            <div
              className="feature-left-item hover-effect wow fadeInDown"
              data-wow-delay=".4s"
            >
              <span>
                {" "}
                <img src="assets/img/part-2.png" alt="" />
              </span>
            </div>
            <div
              className="feature-left-item hover-effect wow fadeInUp"
              data-wow-delay=".4s"
            >
              <span>
                {" "}
                <img src="assets/img/part-3.png" alt="" />
              </span>
            </div>
            <div
              className="feature-left-item hover-effect wow fadeInUp"
              data-wow-delay=".2s"
            >
              <span>
                {" "}
                <img src="assets/img/part-4.png" alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="feature-right">
            <div className="feature-right-img">
              <img src="assets/img/f1.jpg" alt="" />
            </div>
            <h6 className="feature-right-title">MEDIA ROOMS</h6>
          </div>
          <div className="feature-right">
            <div className="feature-right-img">
              <img src="assets/img/f3.jpg" alt="" />
            </div>
            <h6 className="feature-right-title">DEDICATED CINEMA SPACES</h6>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="feature-right">
            <div className="feature-right-img">
              <img src="assets/img/f2.jpg" alt="" />
            </div>
            <h6 className="feature-right-title"> DENS</h6>
          </div>
          <div className="feature-right">
            <div className="feature-right-img">
              <img src="assets/img/f4.jpg" alt="" />
            </div>
            <h6 className="feature-right-title">MUSIC SYSTEMS </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Feature <<===== 
    =========================== */}
  {/* ===========================
    =====>> Reliable <<===== */}
  <section id="reliable-area" className="reliable-bg bg-com pb-120 pt-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="reliable-content">
            <h2 className="reliable-content-title">DEDICATED CINEMA SPACES</h2>
            <p>
              For TV and movie lovers looking for that ultimate experience, a
              Dedicated Cinema Space is the only way to go. We design and
              engineer every detail of a space to make it look as incredible as
              it sounds.
            </p>
            <ul className="twocolumn list-icons">
              <li>Projector or TV</li>
              <li>Sound System</li>
              <li>Seating</li>
              <li>Lighting</li>
              <li>Decor</li>
              <li>Convenience</li>
              <li>Customization</li>
              <li>Cost-effective</li>
            </ul>
          </div>
        </div>
        {/*  <div class="col-lg-5 offset-lg-1">
              <div class="reliable-img bg-com">
                  <a class="popup-video" href="https://www.youtube.com/watch?v=vpx6eO4cG-Q">
                      <i class="fab fa-youtube"></i>
                  </a>
              </div>
          </div> */}
      </div>
    </div>
  </section>
  {/* =====>> End Reliable <<===== 
    =========================== */}
  {/* ===========================
    =====>> Secrius Details <<===== */}
  <section id="secrius-details-area" className="pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="feature-right-img">
            <img src="assets/img/secrius-call.jpg" alt="" />
          </div>
        </div>
        <div className="col-lg-6 ">
          <div className="secrius-details-right">
            <h2 className="hading-title">
              We are Open <span>24 Hours</span>
              Day for installation
            </h2>
            <p className="blue">
              We have the skilled and qualified staffs to install Bring to the
              table{" "}
            </p>
            <p>
              We have the skilled and qualified staffs to install the security
              system protects your place and introduced. we expect to continue
              our relationship long after.
            </p>
            <ul className="twocolumn list-icons">
              <li>Experienced technicians</li>
              <li>Very Cheap Rates</li>
              <li>Certified Materials</li>
              <li>Very Cheap Rates</li>
              <li>Online Support</li>
              <li>Free Estimate</li>
            </ul>
            <hr />
            <p className="blue">
              We can also save you money on your monthly utility by installment
            </p>
            <p>
              We have the skilled and qualified staffs to install the security
              system protects your place and introduced. we expect to continue
              our relationship long after your new equipment is installed. It
              speaks to one of the best venture.
            </p>
            <ul className="list-icons">
              <li>
                Your trusted hometown Indianapolis plumber for almost 20 years
              </li>
              <li>
                {" "}
                We are local, honest, and professional plumbers proudly serving{" "}
              </li>
              <li>We use years of experience and training to make sure.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Secrius Details <<===== 
    =========================== */}
  {/* ===========================
    =====>> Clint Review <<===== */}
  <section id="clint-review-area" className="clint-review-c pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <h2 className="hading-title">
            More then <span>20k+ clients </span>
            satisfied our works
          </h2>
        </div>
      </div>
      {/* Clint Review */}
      <div className="row">
        <div className="col-lg-12">
          <div className="clint-review-slider owl-carousel owl-theme">
            <div className="clint-review-item">
              <div className="clint-review-text">
                <h6>Great service ! </h6>
                <p>
                  {" "}
                  Everything is working great and I appreciate our team taking
                  the number route installer.
                </p>
                <div className="clint-name">
                  <i className="fas fa-quote-left" /> Michel Alatt
                </div>
              </div>
              <div className="clint-review-img">
                <img src="assets/img/r1.png" alt="" />
              </div>
            </div>
            <div className="clint-review-item">
              <div className="clint-review-text">
                <h6>Great service ! </h6>
                <p>
                  {" "}
                  Everything is working great and I appreciate our team taking
                  the number route installer.
                </p>
                <div className="clint-name">
                  <i className="fas fa-quote-left" /> Michel Alatt
                </div>
              </div>
              <div className="clint-review-img">
                <img src="assets/img/r2.png" alt="" />
              </div>
            </div>
            <div className="clint-review-item">
              <div className="clint-review-text">
                <h6>Great service ! </h6>
                <p>
                  {" "}
                  Everything is working great and I appreciate our team taking
                  the number route installer.
                </p>
                <div className="clint-name">
                  <i className="fas fa-quote-left" /> Michel Alatt
                </div>
              </div>
              <div className="clint-review-img">
                <img src="assets/img/r2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Logo Footer */}
      <div className="row">
        <div className="col-lg-12">
          <div className="logo-footer-title">
            <h4>Trusted By 30,000 Companies Worldwide</h4>
          </div>
          <div className="logo-footer-slider owl-carousel owl-theme">
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/1.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/2.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/3.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/4.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/1.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/2.png" alt="" />
              </a>
            </div>
            <div className="logo-footer-item">
              <a href="#">
                <img src="assets/img/logo/3.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Clint Review <<===== 
    =========================== */}
  {/* ===========================
    =====>> Team <<===== */}
  <section id="reliable-area" className="reliable-bg-1 bg-com pb-120 pt-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="reliable-content text-center">
            <h2 className="hading-title">
              Home Cinema <span>Speaker</span>
            </h2>
            <p>
              Transform your living room into a cinematic oasis with our range
              of home cinema speakers. Immerse yourself in crystal-clear audio
              that brings your favorite movies, TV shows, and music to life like
              never before.
            </p>
            <p>
              Experience every whisper, explosion, and musical note in stunning
              detail with our state-of-the-art speaker systems. Designed with
              precision and engineered for excellence, our speakers deliver an
              unparalleled audio experience that transports you right into the
              heart of the action.
            </p>
          </div>
        </div>
        {/*  <div class="col-lg-5 offset-lg-1">
              <div class="reliable-img bg-com">
                  <a class="popup-video" href="https://www.youtube.com/watch?v=vpx6eO4cG-Q">
                      <i class="fab fa-youtube"></i>
                  </a>
              </div>
          </div> */}
      </div>
    </div>
  </section>
  <section id="team-area" className="pt-120 pb-120">
    <div className="container">
      <div className="row ">
        <div className="team-slide owl-carousel owl-theme">
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (1).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (2).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (3).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (4).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (5).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (6).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (7).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/Homespeakers/home-speakers (8).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* =====>> End Team <<===== 
    =========================== */}
  <section id="reliable-area" className="reliable-bg-2 bg-com pb-120 pt-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="reliable-content text-center">
            <h2 className="reliable-content-title">
              Amplifiers &amp; Receivers
            </h2>
            <p>
              Unlock the full potential of your audio system with our range of
              amplifiers and receivers. Whether you're a seasoned audiophile or
              just getting started, our cutting-edge technology and premium
              craftsmanship ensure unparalleled sound quality and performance.
            </p>
            <p>
              Amplifiers serve as the powerhouse of your audio setup, delivering
              the raw power needed to drive your speakers and reproduce sound
              with clarity and precision. Our amplifiers are meticulously
              engineered to minimize distortion and maximize efficiency,
              allowing you to experience your favorite music and movies with
              breathtaking clarity and detail.
            </p>
          </div>
        </div>
        {/*  <div class="col-lg-5 offset-lg-1">
              <div class="reliable-img bg-com">
                  <a class="popup-video" href="https://www.youtube.com/watch?v=vpx6eO4cG-Q">
                      <i class="fab fa-youtube"></i>
                  </a>
              </div>
          </div> */}
      </div>
    </div>
  </section>
  <section id="team-area" className="pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="team-slide owl-carousel owl-theme">
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (1).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (2).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (3).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (4).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (5).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (6).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (7).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (8).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/amp/amp (9).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="reliable-area" className="reliable-bg-3 bg-com pb-120 pt-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="reliable-content text-center">
            <h2 className="reliable-content-title">Projectors</h2>
            <p>
              Step into a world of larger-than-life entertainment with our range
              of cutting-edge projectors. Whether you're hosting a movie night
              at home, delivering a presentation in the office, or creating an
              immersive gaming experience, our projectors are designed to
              captivate and inspire.
            </p>
            <p>
              Experience the thrill of cinema-quality visuals in the comfort of
              your own home with our home theater projectors. Featuring stunning
              HD or even 4K resolution, vibrant colors, and crisp clarity, our
              projectors transform any blank wall or screen into a mesmerizing
              movie experience. From action-packed blockbusters to heartwarming
              family films, every detail comes to life with breathtaking
              realism.
            </p>
          </div>
        </div>
        {/*  <div class="col-lg-5 offset-lg-1">
              <div class="reliable-img bg-com">
                  <a class="popup-video" href="https://www.youtube.com/watch?v=vpx6eO4cG-Q">
                      <i class="fab fa-youtube"></i>
                  </a>
              </div>
          </div> */}
      </div>
    </div>
  </section>
  <section id="team-area" className="pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="team-slide owl-carousel owl-theme">
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (1).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (2).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (3).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (4).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (5).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SOURCES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (6).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>AMPLIFIERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (7).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>SPEAKERS</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (8).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (9).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
          <div className="team-item">
            <div className="team-img">
              <img src="assets/img/projectors/pro (10).jpg" alt="" />
            </div>
            <div className="team-text">
              <div className="team-name">
                <h6>CABLES</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* ===========================
    =====>> Footer <<===== */}
  <footer
    id="footer-area"
    className="bg-black section-color bg-com pt-80 pb-80"
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <ul className="footer-copy-logo pb-70">
            <li>
              <a href="#">
                <img src="assets/img/logo.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/img/logo/f2.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/img/logo/f3.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/img/logo/f4.png" alt="" />
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-widget">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget-item mt-40">
              <h6 className="footer-widget-title">Contact Info</h6>
              <p>
                No 47 Kamarajar Salai, Near Anjaneyar Temple, Karaikal - 609602
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget-item mt-40">
              <h6 className="footer-widget-title">Quick links</h6>
              <ul className="footer-link">
                <li>
                  <a href="#"> Home</a>
                </li>
                <li>
                  <a href="#"> About us</a>
                </li>
                <li>
                  <a href="#"> Franchise</a>
                </li>
                <li>
                  <a href="#"> Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget-item mt-40">
              <h6 className="footer-widget-title">Important Links</h6>
              <ul className="footer-link">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#"> Terms and Conditions</a>
                </li>
                <li>
                  <a href="#"> Return &amp; Refund Policy</a>
                </li>
                <li>
                  <a href="#"> Cancellation Policy</a>
                </li>
              </ul>
            </div>
          </div>
          {/*   <div class="col-lg-3 col-md-6">
                  <div class="footer-widget-item mt-40">
                      <h6 class="footer-widget-title">support</h6>
                      <ul class="footer-link">
                          <li><a href="#"> Support Center</a></li>
                          <li><a href="#"> Terms of Use</a></li>
                          <li><a href="#"> Privacy Policy</a></li>
                          <li><a href="#"> Product Returns</a></li>
                          <li><a href="#"> Choose the Right System</a></li>
                      </ul>
                  </div>
              </div> */}
        </div>
      </div>
    </div>
  </footer>
  {/* =====>> End Footer <<===== 
    =========================== */}
  {/* ===========================
    =====>> Copy Right <<===== */}
  <div id="copy-right-area" className="section-color bg-black pt-30 pb-30">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="cta-text d-flex flex-lg-row flex-column align-items-center justify-content-start">
            {/* <div class="text-lg-left text-center">
                      <p>Designed & Developed By Secrius</p>
                  </div> */}
            <div className="ml-lg-auto">
              <div className="d-flex flex-row align-items-center justify-content-center">
                <p>© Copyrights 2024 Technofixes All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* =====>> End Copy Right <<===== 
    =========================== */}
  {/* ===========================
    =====>> Top Preloader <<===== */}
 
  {/* =====>> End Top Preloader <<===== 
    =========================== */}
  {/* ===========================
    =====>> Scroll Top <<===== */}
  <a href="#" className="scroll-top show" id="scroll-top">
    <i className="arrow-top fal fa-long-arrow-up" />
    <i className="arrow-bottom fal fa-long-arrow-up" />
  </a>
  {/* =====>> End Scroll Top <<===== 
    =========================== */}
</>

  );
}

export default App;
